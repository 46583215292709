import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError} from 'rxjs/operators';
import { header } from 'src/app/_helpers/tools/header.tool';

import { Router } from '@angular/router';

import { StorageService } from '../../_services/storage.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private _storageService: StorageService, 
    private http: HttpClient,
    private router: Router) { }

  get user(){
    return this._storageService.getItem("user");
  }

  login(data: { numberDocument: string }): Observable<any>{
    console.log("Copitas", data);
    return this.http.post<any>(
      `${environment.apiUrl}auth`, JSON.stringify(data), { headers: header }
    ).pipe(
      map((auth: any) => {
        console.log("??????????")
        this._storageService.setItem("user",auth);
        this._storageService.setItem("token", auth.token);
        this.router.navigate(['/']);
        return auth;
      }),
      catchError((err) => {
        console.error('err', err);
        return of(err.error);
      })
    );
  }

  logout() {
    this._storageService.removeItem("user");
    console.log("On logout");
    this.router.navigate(['/signup'], {
      queryParams: {},
    });
  }
}
